var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"mb-5 px-5"},[_c('h5',[_vm._v("Add Category")]),_c('p',{staticClass:"text-muted small"},[_vm._v(" Please fill out the text field below to add a category. ")]),_c('hr'),_c('div',{staticClass:"col d-flex mb-5 mt-5"},[_c('b-form-group',{staticClass:"flex-grow-1 mb-0",attrs:{"id":"new-category-group","label":"Category Name","label-for":"new-category"}},[_c('b-form-input',{attrs:{"id":"new-category","type":"text"},model:{value:(_vm.newCategoryField),callback:function ($$v) {_vm.newCategoryField=$$v},expression:"newCategoryField"}})],1),_c('b-button',{ref:"addCategory",staticClass:"ml-3 align-self-end font-weight-bold",attrs:{"variant":"success"},on:{"click":_vm.addCategory}},[_c('i',{staticClass:"la la-check sm mr-2"}),_vm._v(" Submit")])],1)]),_c('b-card',[_c('h5',{staticClass:"mb-5 col"},[_vm._v("Categories")]),_c('b-row',{staticClass:"mt-5"},[_vm._l((_vm.categories),function(item,index){return _c('div',{key:index,staticClass:"col-md-4 my-5"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"col text-muted symbol symbol-25 mb-0 mr-5"},[_c('span',{staticClass:"symbol-label small font-weight-bold"},[_vm._v(_vm._s(index + 1)+".")])]),_c('b-form-input',{attrs:{"type":"text","value":item.categoryName},model:{value:(_vm.categories[index]['categoryName']),callback:function ($$v) {_vm.$set(_vm.categories[index], 'categoryName', $$v)},expression:"categories[index]['categoryName']"}}),_c('b-button',{ref:`save-${item._id}`,refInFor:true,staticClass:"ml-3 px-3",attrs:{"variant":"info"},on:{"click":function($event){return _vm.updateCategory(
                _vm.categories[index]['categoryName'],
                item._id,
                index
              )}}},[_c('i',{staticClass:"la la-save icon-md p-0"})]),_c('b-button',{ref:`delete-${item._id}`,refInFor:true,staticClass:"ml-3 px-3",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteCategory(item._id, index)}}},[_c('i',{staticClass:"la la-trash icon-md p-0"})])],1)])}),_vm._l((_vm.categories.length % 3 > 0
          ? _vm.categories.length +
            3 -
            (_vm.categories.length % 3) -
            _vm.categories.length
          : 0),function(n){return _c('div',{key:new Date().getTime() + n,staticClass:"col-md-4 my-5"},[_c('div',{staticClass:"card bg-light b-0",staticStyle:{"height":"calc(1.5em + 1.3rem + 2px)"}})])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }