<template>
  <div>
    <b-card class="mb-5 px-5">
      <h5>Add Category</h5>
      <p class="text-muted small">
        Please fill out the text field below to add a category.
      </p>
      <hr />
      <div class="col d-flex mb-5 mt-5">
        <b-form-group
          class="flex-grow-1 mb-0"
          id="new-category-group"
          label="Category Name"
          label-for="new-category"
        >
          <b-form-input
            id="new-category"
            type="text"
            v-model="newCategoryField"
          ></b-form-input>
        </b-form-group>
        <b-button
          ref="addCategory"
          class="ml-3 align-self-end font-weight-bold"
          variant="success"
          @click="addCategory"
          ><i class="la la-check sm mr-2"></i> Submit</b-button
        >
      </div>
    </b-card>
    <b-card>
      <h5 class="mb-5 col">Categories</h5>
      <b-row class="mt-5">
        <div
          class="col-md-4 my-5"
          v-for="(item, index) in categories"
          :key="index"
        >
          <div class="d-flex">
            <p class="col text-muted symbol symbol-25 mb-0 mr-5">
              <span class="symbol-label small font-weight-bold"
                >{{ index + 1 }}.</span
              >
            </p>
            <b-form-input
              type="text"
              v-model="categories[index]['categoryName']"
              :value="item.categoryName"
            ></b-form-input>
            <b-button
              class="ml-3 px-3"
              variant="info"
              :ref="`save-${item._id}`"
              @click="
                updateCategory(
                  categories[index]['categoryName'],
                  item._id,
                  index
                )
              "
              ><i class="la la-save icon-md p-0"></i>
            </b-button>
            <b-button
              class="ml-3 px-3"
              variant="danger"
              :ref="`delete-${item._id}`"
              @click="deleteCategory(item._id, index)"
              ><i class="la la-trash icon-md p-0"></i>
            </b-button>
          </div>
        </div>
        <div
          class="col-md-4 my-5"
          v-for="n in categories.length % 3 > 0
            ? categories.length +
              3 -
              (categories.length % 3) -
              categories.length
            : 0"
          :key="new Date().getTime() + n"
        >
          <div
            class="card bg-light b-0"
            style="height: calc(1.5em + 1.3rem + 2px)"
          ></div>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_CATEGORIES,
  ADD_CATEGORIES,
  UPDATE_CATEGORIES,
  DELETE_CATEGORY,
} from "@/core/services/store/categories.module";
export default {
  created() {
    this.getCategories();
  },
  data() {
    return {
      isLoading: false,
      // categories: [],
      newCategoryField: null,
      isAdding: false,
    };
  },
  methods: {
    getCategories() {
      // this.isLoading = true;
      this.$store
        .dispatch(GET_CATEGORIES)
        .then(() => {})
        .catch(console.log);
    },
    deleteCategory(id, index) {
      let deleteCategoryButton = this.$refs[`delete-${id}`][0];
      deleteCategoryButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right"
      );

      this.$store
        .dispatch(DELETE_CATEGORY, {
          categoryId: id,
          index,
        })
        .then(() => {
          this.newCategoryField = null;
          this.$bvToast.toast(`Category successfully deleted`, {
            title: `Deleted`,
            variant: "success",
            solid: true,
            appendToast: true,
          });
          // this.categories = [this.categories, data];
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: `Failed to delete category`,
            variant: "danger",
            solid: true,
            appendToast: true,
          });
        })
        .finally(() => {
          deleteCategoryButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
    updateCategory(name, id, index) {
      let updateCategoryButton = this.$refs[`save-${id}`][0];
      updateCategoryButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right"
      );

      this.$store
        .dispatch(UPDATE_CATEGORIES, {
          categoryName: name,
          categoryId: id,
          index,
        })
        .then(() => {
          this.newCategoryField = null;
          this.$bvToast.toast(`Category successfully updated`, {
            title: `Updated`,
            variant: "success",
            solid: true,
            appendToast: true,
          });
          // this.categories = [this.categories, data];
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: `Failed to update category`,
            variant: "danger",
            solid: true,
            appendToast: true,
          });
        })
        .finally(() => {
          updateCategoryButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
    addCategory() {
      // set spinner to submit button
      const addCategoryButton = this.$refs["addCategory"];
      addCategoryButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right"
      );

      this.$store
        .dispatch(ADD_CATEGORIES, { categoryName: this.newCategoryField })
        .then(() => {
          this.newCategoryField = null;
          this.$bvToast.toast(`Category successfully added`, {
            title: `Submitted`,
            variant: "success",
            solid: true,
            appendToast: true,
          });
          // this.categories = [this.categories, data];
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: `Failed to submit category`,
            variant: "danger",
            solid: true,
            appendToast: true,
          });
        })
        .finally(() => {
          addCategoryButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
  },
  computed: {
    ...mapGetters(["categories"]),
  },
};
</script>